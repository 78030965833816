<template>
    <div>
        <Table
            ref="performanceTable"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :class="{ sum: !isSelf }"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel', 'zooming']"
            :isFocusRow="true"
            :isLoading="isLoading"
            :sortDefault="{ sort: 'desc', sortBy: 'fyb' }"
            :isPagination="true"
            :hiddenColumnDefault="hiddenCols"
            :columnsMode="columnsMode"
            :isFixedHeader="true"
        >
            <template #insured="{ isHeader, row }">
                <router-link
                    v-if="!isHeader && !row.className"
                    class="customer"
                    :to="{
                        name: 'TiaCustomer',
                        query: {
                            customerCode: row.insuredCode,
                            customerName: row.insured
                        }
                    }"
                >
                    {{ row.insured }}
                </router-link>
            </template>
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { performanceColumns } from '@/assets/javascripts/performance/setting'

export default {
    name: 'PerformanceTable',
    components: {
        Table
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        inoCounts: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        transformData: function (rows) {
            /* ***
             * 序列號依照業績排，套用篩選要重編號、排序不重編號。
             * 故不能進Table元件才依照繼續率排序，要直接改寫資料源的排序。
             *** */
            const inoCounts = _.cloneDeep(this.inoCounts)
            inoCounts
            return rows
                .map((row) => {
                    if (this.isSelf) {
                        // 個人業績才有的
                        row.receiveDate = row.receiveDate?.slice(0, 10)
                        row.effectDate = row.effectDate?.slice(0, 10)
                        row.yPeriod = parseInt(row.yPeriod)
                        row.count = this.$toPercentage(row.count || 0, 0)
                        row.fyn =
                            inoCounts[row.insNo] > 0
                                ? inoCounts[row.insNo]--
                                : undefined
                        if (row.isMobileInsured) {
                            row.isMobileInsured = '是'
                        } else if (
                            !row.isMobileInsured &&
                            row.isMobileInsured !== null
                        ) {
                            row.isMobileInsured = '否'
                        }
                        if (row.isFourWin) {
                            row.isFourWin = '是'
                        } else if (!row.isFourWin && row.isFourWin !== null) {
                            row.isFourWin = '否'
                        }

                        row.jobTypes = row.jobType
                        row.isGeneral = row.isGeneral ? '是' : '否'
                        row.isSpecial = row.isSpecial ? '是' : '否'
                    } else {
                        let rate
                        // 壽險
                        row.lifeRfyp = row.life?.rfyp
                        row.lifeFyb = row.life?.fyb
                        row.lifeFyn = row.life?.fyn
                        rate = row.lifeFyb / row.lifeRfyp || 0
                        row.lifeFybRfypRate = this.$toPercentage(rate, 2)
                        // 產險
                        row.propertyRfyp = row.property?.rfyp
                        row.propertyFyb = row.property?.fyb
                        row.propertyFyn = row.property?.fyn
                        rate = row.propertyFyb / row.propertyRfyp || 0
                        row.propertyFybRfypRate = this.$toPercentage(rate, 2)
                        // 團/旅平險
                        row.groupRfyp = row.group?.rfyp
                        row.groupFyb = row.group?.fyb
                        row.groupFyn = row.group?.fyn
                        rate = row.groupFyb / row.groupRfyp || 0
                        row.groupFybRfypRate = this.$toPercentage(rate, 2)
                        // 總和/組織業績才有的
                        let rfypSum =
                            row.lifeRfyp + row.propertyRfyp + row.groupRfyp
                        let fybSum =
                            row.lifeFyb + row.propertyFyb + row.groupFyb
                        let fynSum =
                            row.lifeFyn + row.propertyFyn + row.groupFyn
                        row.rfyp = rfypSum || 0
                        row.fyb = fybSum || 0
                        row.fyn = fynSum
                    }
                    // 不論個人或組織都有的欄位
                    const rate = row.fyb / row.rfyp || 0
                    row.fybRfypRate = this.$toPercentage(rate, 2)

                    // fybRfypRate受理CP比需用number型態排序
                    row.fybRfypRateLabel = rate

                    // count比率需用number型態排序
                    row.countLabel = row?.count || 0
                    return row
                })
                .sort((a, b) => b.fyb - a.fyb)
                .map((row, index) => {
                    row.serialNumber = index + 1
                    return row
                })
        }
    },
    computed: {
        detectDatatTyle: function () {
            return this.filterLog.dataType.value
        },
        tableColumnConfig: function () {
            let columns = _.cloneDeep(performanceColumns)
            let removeColumns

            if (this.isSelf) {
                removeColumns = [
                    'lifeRfyp',
                    'lifeFyn',
                    'lifeFyb',
                    'lifeFybRfypRate',
                    'propertyRfyp',
                    'propertyFyn',
                    'propertyFyb',
                    'propertyFybRfypRate',
                    'groupRfyp',
                    'groupFyn',
                    'groupFyb',
                    'groupFybRfypRate',
                    'level'
                ]
                if (this.filterLog.dataType.value === 1) {
                    columns.productName.label = '商品名稱'
                } else if (
                    this.filterLog.dataType.value === 2 ||
                    this.filterLog.dataType.value === 3
                ) {
                    columns.productName.label = '商品代碼'
                }
            } else {
                removeColumns = [
                    'count',
                    'insNo',
                    'supplier',
                    'receiveDate',
                    'effectDate',
                    'jobType',
                    'yPeriod',
                    'payWay',
                    'insured',
                    'productName',
                    'currencyText',
                    'isMobileInsured',
                    'solicitorOri',
                    'isFourWin',
                    'isGeneral',
                    'isSpecial',
                    'jobTypes'
                ]
            }

            columns = _.omit(columns, removeColumns)

            return columns
        },
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        isSelf: function () {
            return this.filterLog.relationMode?.value === 'self'
        },
        excelFileName: function () {
            const name = this.filterLog.manCode?.label ?? ''
            return `業績查詢_${name}`
        },
        setExcelLog: function () {
            const log = _.cloneDeep(this.filterLog)
            let memo = []
            if (this.filterLog.dataType?.value === 1) {
                memo = [
                    'effectDateStart',
                    'effectDateEnd',
                    'commitDateStart',
                    'commitDateEnd'
                ]
            } else if (this.filterLog.dataType?.value === 2) {
                memo = [
                    'receiveDateStart',
                    'receiveDateEnd',
                    'effectDateStart',
                    'effectDateEnd',
                    'isAudit'
                ]
            } else {
                memo = ['isAudit']
            }

            memo.forEach(function (item) {
                delete log[`${item}`]
            })

            return log
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.setExcelLog
            }
        },
        hiddenCols: function () {
            return this.isSelf
                ? [
                      'bgName',
                      'orgBm',
                      'orgBmName',
                      'salesName',
                      'apStuName',
                      'salesTitle'
                  ]
                : []
        },
        columnsMode: function () {
            return this.isSelf ? 'self' : 'org'
        }
    },
    data() {
        return {
            inoCount: 0,
            sumCardSet: null
        }
    }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    :deep(.row-container) {
        a {
            color: $fourth-blue !important;
            cursor: pointer;
            &.customer {
                text-decoration: underline;
            }
        }
    }
}
:deep(.card) {
    @media screen and (max-width: 576px) {
        .row-container .row.body {
            &.loading {
                .serial-number,
                .sales-name,
                .sales-title,
                .level {
                    display: none;
                }
            }
            &.sum-row {
                .bg-name,
                .org-bm,
                .org-bm-name,
                .sales-name,
                .ap-stu-name,
                .sales-title,
                .level,
                .count,
                .supplier,
                .ins-no,
                .receive-date,
                .effect-date,
                .job-type,
                .y-period,
                .pay-way,
                .insured,
                .main-pro-name,
                .currency-text,
                .is-four-win,
                .is-mobile-insured,
                .solicitor-ori,
                .product-name,
                .job-types,
                .is-general,
                .is-special,
                .is-special {
                    display: none;
                }
            }
            .cell {
                &.serial-number {
                    order: -10;
                    font-weight: 600;
                    margin-right: 5px;
                    width: auto;
                    &:before {
                        display: none;
                    }
                    &:after {
                        content: '.';
                    }
                }
                &.sales-name {
                    order: -9;
                    width: auto;
                    margin-right: 5px;
                    font-weight: 600;
                    &:before {
                        display: none;
                    }
                }
                &.sales-title {
                    order: -8;
                    width: auto;
                    border-bottom: none;
                    padding: 0;
                    margin-right: 5px;
                    font-weight: 600;
                    &:before {
                        display: none;
                    }
                }
                &.level {
                    order: -7;
                    width: auto;
                    font-weight: 600;
                    &:before {
                        content: '第';
                        margin-right: 0;
                        font-size: 14px;
                        color: $sixth-black;
                    }
                    &:after {
                        content: '代';
                    }
                }
                &.ap-stu-name {
                    padding-bottom: 10px;
                    border-bottom: 1px dashed $fourth-grey;
                }
                &.main-pro-name {
                    text-align: left;
                }
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}
</style>
